import React, { useMemo } from 'react';
import { graphql, Link, PageProps } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import cls from 'classnames';
import ArticleSummaryBlocks from '../components/TotLife/ArticleSummary-Blocks';
import LayoutMain from '../components/layouts/LayoutMain';
import SEO from '../components/seo/SEO';
import SectionBuyNow from '../components/sectionBuyNow/SectionBuyNow';
import TopicList from "../components/TotLife/TopicList";
import * as styles from './TotLifeBlog.module.scss';


type PageContext = { uid: string; tags: string[] };

const TotLifeBlog = ({ data }: PageProps<PageContext>) => {
  const { allPrismicWelltotBlogBlog, prismicWelltotBlogBlog } = data;
  const image = getImage(prismicWelltotBlogBlog?.data?.blog_image?.gatsbyImageData);
  const description = useMemo(() => {
    const firstParagraph = prismicWelltotBlogBlog?.data?.body?.richText.find(
      ({ type }: { type: string }) => type === 'paragraph',
    );
    return firstParagraph?.text ?? '';
  }, [prismicWelltotBlogBlog]);

  const title = prismicWelltotBlogBlog?.data?.title?.text ?? '';

  return (
    <LayoutMain>
        <SEO article title={title} description={description} />

        {/* Blog title and published date */}
        <div className={styles.container}>

            <div className={styles.headerCopy}>
                <h1>{prismicWelltotBlogBlog?.data?.title?.text}</h1>
                <p>Published on {prismicWelltotBlogBlog?.last_publication_date}</p>
            </div>

            {/* Blog content and topics */}
            <div className={styles.blogImage}>
                {image && (
                  <GatsbyImage
                    image={image}
                    alt={prismicWelltotBlogBlog?.data?.blog_image?.alt ?? ''}
                    className={styles.image}
                  />
                )}
            </div>

            <div className={styles.blogCopy}>
                <div dangerouslySetInnerHTML={{ __html: prismicWelltotBlogBlog?.data?.body?.html }} ></div>
                <div dangerouslySetInnerHTML={{ __html: '<br/><p><small>' + prismicWelltotBlogBlog?.data?.lbl + '</small></p>'}} ></div>
            </div>

            <div className={styles.topics}>
                <h2>Topics</h2>
                <TopicList />
            </div>

            {/* Buy Button */}
            <div className={styles.buyNowButton}>
                <SectionBuyNow/>
            </div>

            {/* Blod related content */}
            <div className={styles.relatedBlogs}>
                <ArticleSummaryBlocks articles={allPrismicWelltotBlogBlog.edges} />
            </div>

        </div>
    </LayoutMain>
  );
};

export const query = graphql`
  query BlogPage($uid: String, $tags: [ID]) {
    prismicWelltotBlogBlog(uid: { eq: $uid }) {
      last_publication_date(formatString: "LL")
      data {
        title {
          text
        }
        body {
          richText
          html
          text
        }
        blog_image {
          alt
          gatsbyImageData(
            width: 1200
            aspectRatio: 1.333
          )
        }
        lbl
      }
    }
    # Get title and image of latest 3 blogs with same tags (but not the article itself)
    allPrismicWelltotBlogBlog(
      filter: { data: { tags1: { elemMatch: { tags: { id: { in: $tags } } } } }, uid: { ne: $uid } }
      limit: 3
      sort: { fields: last_publication_date, order: DESC }
    ) {
      edges {
        node {
          url
          data {
            title {
              text
            }
            blog_image {
              alt
              gatsbyImageData(
                width: 400
                aspectRatio: 1.333
              )
            }
          }
        }
      }
    }
  }
`;

export default TotLifeBlog;
