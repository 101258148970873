import { graphql, Link, useStaticQuery } from 'gatsby';
import React from 'react';
import cls from 'classnames';
import * as styles from './TopicList.module.scss';

type Props = {
  // Prismic blog topic ID
  topicId: string;
  onTopicClick?: (newTopic: string, topicUrl: string) => void;
};

const TopicList = ({ topicId, onTopicClick }: Props) => {
  const { allPrismicWelltotBlogBlogTopic } = useStaticQuery(query);

  const handleClick =
    (newTopic: string, topicUrl: string) =>
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      if (!onTopicClick) return;

      e.preventDefault();
      onTopicClick(newTopic, topicUrl);
    };

  return (
      <div className={styles.container}>
        <ul>
          <li>
            <Link
              to="/tot-life"
              className={cls(styles.link, topicId === '' && styles.active)}
              onClick={handleClick('', '/tot-life')}
            >
              All
            </Link>
          </li>
          {allPrismicWelltotBlogBlogTopic.nodes.map(({ data, uid, url }) => (
            <li key={data?.topic_name?.text}>
              <Link
                to={url ?? ''}
                className={cls(styles.link, topicId === uid && styles.active)}
                onClick={handleClick(uid, url ?? '')}
              >
                {data?.topic_name?.text}
              </Link>
            </li>
          ))}
        </ul>
      </div>

  );
};

const query = graphql`
  query TopicList {
    allPrismicWelltotBlogBlogTopic {
      nodes {
        uid
        url
        data {
          topic_name {
            text
          }
        }
      }
    }
  }
`;

export default TopicList;
